import axios from '@axios'

export const uploadSingleFile = (containerName, file) => new Promise((resolve, reject) => {
  const formData = new FormData()
  formData.append('containerName', containerName)
  formData.append('file', file)

  axios.post('/fa/attachfile/single', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => resolve(response))
    .catch(error => reject(error))
})

export const uploadMultiFiles = (containerName, files) => new Promise((resolve, reject) => {
  const formData = new FormData()
  formData.append('containerName', containerName)
  for (let i = 0; i < files.length; i += 1) {
    formData.append('files', files[i])
  }

  axios.post('/fa/attachfile/multi', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => resolve(response))
    .catch(error => reject(error))
})
