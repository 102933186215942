<template>
  <div>
    <editor
      v-model="editorValue"
      :init="init"
    />
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'

import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/themes/silver'

import 'tinymce/icons/default'

import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/textcolor'

import 'tinymce-i18n/langs5/ko_KR'

import Editor from '@tinymce/tinymce-vue'
import { uploadSingleFile } from '@core/utils/commAxios'

export default {
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      init: {
        language: 'ko_KR',
        height: 500,
        content_style: `
          #tinymce[contenteditable=true] table:first-child {
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-collapse: collapse;
            border-color: initial;
            border-style: dotted;
          }
          
          #tinymce #emptyHtmlDrInfo p:first-child {
            margin-block-start: 0px;
          }
          
          #tinymce #emptyHtmlDrInfo p:last-child {
            margin-block-end: 0px;
          }
        `,
        entity_encoding: 'named',
        menubar: true,
        statusbar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table contextmenu textcolor',
        ],
        font_formats: '맑은고딕=malgun gothic;굴림=굴림;돋음=돋음;바탕=바탕;궁서=궁서;Arial=Arial;Times=Times;Verdana=Verdana',
        toolbar: 'fontselect forecolor backcolor fontsizeselect insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code link image jbimages preview',
        relative_urls: false,
        automatic_uploads: true,
        remove_script_host: 0,
        paste_data_images: true,
        paste_auto_cleanup_on_paste: false,
        paste_retain_style_properties: 'all',
        convert_urls: false,
        file_picker_types: 'image',
        /* and here's our custom image picker */
        file_picker_callback(cb) { // , value, meta) {
          const input = document.createElement('input')

          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'image/*')

          // eslint-disable-next-line func-names
          input.onchange = function () {
            const file = this.files[0]
            uploadSingleFile('editimage', file)
              .then(rs => {
                const { blobCache } = tinymce.activeEditor.editorUpload
                const { id } = rs.data.id
                const uri = rs.data.filePath

                const blobInfo = {
                  id: () => id,
                  blobUri: () => uri,
                }
                blobCache.add(blobInfo)
                // tinymce.activeEditor.insertContent(`<img src="${uri}"/>`)

                cb(blobInfo.blobUri(), { title: file.name })
              })
              .catch(error => {
                console.error('Error uploading image', error)
              })
          }
          input.click()
        },
      },
      editorValue: this.value === null ? (this.isTable === false ? this.value : `<table style="width: 270px; max-width: 270px; height: 160px; max-height: 160px; font-size: 10pt;"><tbody><tr><td></td></tr></tbody></table>`) : this.value,
    }
  },
  watch: {
    value(val) {
      this.editorValue = val === null ? (this.isTable === false ? val : `<table style="width: 270px; max-width: 270px; height: 160px; max-height: 160px; font-size: 10pt;"><tbody><tr><td></td></tr></tbody></table>`) : val
    },
    editorValue(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    tinymce.init({})
  },
  methods: {},
}
</script>
